import { useEffect, useState } from "react";
import { LayoutDefault } from "@components/layout/Default";
import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { Button } from "@components/atomic/Button/Button";
import { Alert } from "@components/atomic/Alert/Alert";
import { getTokens, resendValidation } from "@services/tokens";

import imgApi from "@assets/images/galaxy/img-3.png";
import imgPerson from "@assets/images/sidebar/img-2.jpg";

export default function Documentation() {
  const [userTokens, setUserTokens] = useState<
    { descricao: string; token: string, valid: boolean }[]
  >([]);
  const [resentEmailMessage, setResentEmailMessage] = useState("");
  const { user } = useSelector(getUser);

  const getUserTokens = async () => {
    try {
      const localUserTokens = await getTokens(user);
      setUserTokens(localUserTokens.data);
    } catch (error) {
      console.log("Erro ao obter tokens:", error);
      setUserTokens([]);
    }
  }

  useEffect(() => {
    getUserTokens();
  }, []);

  useEffect(() => {
    getUserTokens();
  }, [user]);

  const handleSwaggerClick = () => {
    window.location.href = window.location.host.startsWith('dashboard') ? 
      "https://idcid.apifacialid.com.br/swagger-ui/index.html" :
      "https://gateway.apifacialid.com.br/swagger-ui/index.html";
  };

  const handleResendVerification = async () => {
    setResentEmailMessage("Aguarde...");
    try {
      await resendValidation(user);
      setResentEmailMessage("E-mail de ativação foi enviado.");
    } catch (error) {
      console.log("Erro ao reenviar E-mail:", error);
      setResentEmailMessage("Erro ao reenviar e-mail, tente novamente em breve.");
    }
  };

  return (
    <>
      <LayoutDefault>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Documentação</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4>API</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-9">
                      <p>
                        Para verificar quais endpoints nossa API disponibiliza
                        você pode acessar o Swagger clicando abaixo:
                      </p>
                      <p>
                        <Button color="info" onClick={handleSwaggerClick}>
                          Acesso ao Swagger
                        </Button>
                        <hr />
                      </p>
                      <p>
                        Todas as requisições necessitam do token para a
                        autorização. Utilizamos JWT no token da API. Abaixo,
                        encontram-se os seus tokens. Caso os mesmos não estejam
                        sendo exibidos é necessário validar o seu e-mail.
                      </p>

                      <h3>Meus tokens</h3>

                      <hr />

                      {userTokens.map(
                        (token: { descricao: string; token: string }) => (
                          <>
                            <div className="row">
                              <div className="col-sm-12">
                                <p>
                                  <strong>Descrição:</strong> {token.descricao}
                                </p>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={token.token}
                                    onFocus={(e) => e.target.select()}
                                  />
                                  <Button
                                    color="info"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        token.token
                                      );
                                    }}
                                  >
                                    Copiar
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )}
                      {userTokens[0] && userTokens[0].valid === false && (
                        <div>
                          <>
                            <hr />
                            <Button
                              size="lg"
                              color="info"
                              onClick={handleResendVerification}
                            >
                              Reenviar e-mail de verificação
                            </Button>
                            {`   `}
                            {resentEmailMessage !== "" && (
                              <span className="badge badge-label bg-info">
                                <i className="mdi mdi-circle-medium"></i>
                                {resentEmailMessage}
                              </span>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                    <div className="col-md-3">
                      <img src={imgApi} alt="imagem galaxia" width={"100%"} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4>Biblioteca de reconhecimento facial - FaceRecon</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-9">
                      <p>
                        FaceRecon é uma biblioteca criada usando JavaScript que
                        permite a detecção manual ou automática de rostos
                        centralizados na tela. Em resumo, ao utilizar a
                        biblioteca ela interage com a webcam do usuário,
                        permitindo que o mesmo seja guiado na captura de sua
                        foto. Também é possível que esta detecção seja manual ou
                        híbrida. Siga os passos abaixo para utilizar a
                        biblioteca.
                      </p>
                      <h3>Passo 01: Incluíndo a biblioteca em seu HTML</h3>
                      <p>
                        A biblioteca pode ser inserida através da tag script,
                        como demonstrado abaixo:
                      </p>
                      <blockquote className="blockquote">
                        {`<script defer src="https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1"></script>`}
                      </blockquote>
                      <p>
                        Após inserir a biblioteca, um objeto global chamado CID
                        estará disponível em seu documento HTML.
                      </p>
                      <hr />
                      <h3>Passo 02: Instanciando a biblioteca</h3>
                      <p>
                        Abaixo segue um exemplo básico de utilização da
                        biblioteca:
                      </p>
                      <blockquote className="blockquote">
                        {`const faceRecon = new CID.FaceRecon({onDetection: (img) => console.log('Imagem detectada', img)})`}
                      </blockquote>
                      <p>
                        Este é o exemplo mais básico de utilização da
                        biblioteca. Perceba que a biblioteca aceita alguns
                        parâmetros, neste exemplo estamos passando um método
                        chamado onDetection, que deve ser uma função callback
                        que será chamada quando a foto do usuário for detectada.
                      </p>
                      <p>
                        Você não conseguirá ver a foto do usuário pois a mesma
                        está encriptada para ser utilizada pela nossa API.
                      </p>
                      <hr />
                      <h3>Passo 03: Rodando a biblioteca</h3>
                      <p>
                        Após instanciada a constante faceRecon vai conter uma
                        instância do objeto CID.FaceRecon, para inicializar a
                        biblioteca basta executar o comando abaixo:
                      </p>
                      <blockquote className="blockquote">
                        faceRecon.run();
                      </blockquote>
                      <p>
                        A biblioteca inicializará uma estrutura HTML no elemento
                        BODY, ligará a webcam do usuário e passará a detectar o
                        rosto do mesmo. Quando finalizar, vai chamar o método
                        onDetection.
                      </p>
                      <hr />
                      <h3>Código de exemplo</h3>
                      <p>
                        Abaixo segue um exemplo de código contendo todas as
                        opções da biblioteca:
                      </p>
                      <p style={{backgroundColor: "black", color: "white", fontSize: '1.1rem', borderRadius: 5}}>
                        
                        <code>
                          <pre>
                          {`
  <!DOCTYPE html>
  <html lang="pt-br">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Exemplo FaceRecon</title>
  </head>
  <body>
      <div id="elementoOndeFaceReconSeraInstanciado"></div>

      <script defer src="https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1"></script>
      <script>
          const faceRecon = new CID.FaceRecon({
              onDetection: (img) => console.log("imagem detectada", img), // Função call
              element: document.getElementById("elementoOndeFaceReconSeraInstanciado"), // Elemento onde será instanciado
              tolerance: 90, // tolerância de detecção
              automaticDetection: true, // ativa ou desativa detecção automática
              timeToWait: null, // tempo em segundos
              playSize: 1,
          });

          faceRecon.run(); // inicializa a biblioteca

          //Se for necessário destruir a biblioteca, utilize a função faceRecon.destroy();
      </script>
  </body>
  </html>
                          `}
                         </pre>
                        </code>
                       
                      </p>
                      <hr />
                      <h3>Parâmetros:</h3>
                      <p>Segue explicação dos parâmetros disponíveis:</p>
                      <Alert>
                        <strong>onDetection</strong>
                        <br />
                        Função que será chamada quando uma face for detectada
                        automaticamente ou manualmente. Receberá a foto por
                        parâmetro.
                      </Alert>
                      <Alert>
                        <strong>element</strong>
                        <br />
                        Elemento HTML onde a bibilioteca será instanciada. Caso
                        não seja fornecido a biblioteca usará o elemento BODY.
                        <br />
                        Atenção: esta propriedade espera apenas um único
                        elemento HTML, não passe array de objetos.
                      </Alert>
                      <Alert>
                        <strong>tolerance</strong>
                        <br />
                        De acordo com nossos testes o valor 90 é adequado para a
                        maioria dos casos. Quanto mais próximo de 0 mais
                        centralizado e próximo das bordas o rosto deverá estar.
                        Recomendamos cuidado com a utilização desta propriedade.
                      </Alert>
                      <Alert>
                        <strong>automaticDetection</strong>
                        <br />
                        Habilita ou desabilita a a detecção automática. Caso
                        seja setado como false a biblioteca não mais fará a
                        detecção automatica, ficando a captura a cargo do
                        usuário/cliente.
                      </Alert>
                      <Alert>
                        <strong>timeToWait</strong>
                        <br />
                        Em alguns cenários, mesmo com a captura automática
                        ligada, o cliente pode ter dificuldade em realizar a
                        captura. Esta propriedade permite definir um tempo em
                        segundos onde a biblioteca vai parar de tentar detectar
                        o rosto e passará esta ação para o usuário mudando para
                        captura automática.
                      </Alert>
                      <Alert>
                        <strong>playSize</strong>
                        <br />
                        Tamanho de exibição da tela de captura, podendo ser de 
                        1 até 5, sendo o 1 valor padrão. Quanto maior o valor, 
                        maior a tela que será exibida.
                      </Alert>
                      <h3>Ouvindo a detecção de imagem</h3>
                      <p>
                        A biblioteca conta com o parâmetro onDetection, que
                        recebe uma função que será chamada quando ocorrer a
                        captura de uma foto. Mas é possível ouvir um evento que
                        será disparado no elemento onde a biblioteca foi
                        instanciada.
                      </p>
                      <p>
                        No exemplo anterior utilizamos o objeto
                        #elementoFacerecon para instanciar a biblioteca, sendo
                        assim, podemos ouvir o evento onDetection neste mesmo
                        objeto:
                      </p>
                      <blockquote className="blockquote">
                        {`elementoFaceRecon.addEventListener("onDetection", (img) => console.log(img), false);`}
                      </blockquote>
                    </div>
                    <div className="col-md-3 d-none d-md-block">
                      <img src={imgPerson} alt="Imagem pessoa" width={"100%"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDefault>
    </>
  );
}
