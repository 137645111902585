import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { useLocation, Navigate } from "react-router-dom";

const isBrowser = () => typeof window !== "undefined";

const ProtectedRoute = ({ router, children }: any) => {
  let location = useLocation();
  const { user } = useSelector(getUser);

  if (isBrowser() && !user.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
export default ProtectedRoute;
