import { BACKEND } from "@utils/constants";
import axios from "axios";

const axiosClient = axios.create({
  // baseURL: 'https://hml-dashboard.facialid.com.br',
  timeout: 20000
});

export function getRequest(URL: string, config: {}) {
  return axiosClient.get(`${BACKEND}${URL}`, config).then((response) => response);
}

export function postRequest(URL: string, payload: object, config = {}) {
  return axiosClient
    .post(`${BACKEND}${URL}`, payload, config)
    .then((response) => response);
}

export function patchRequest(URL: string, payload: object) {
  return axiosClient.patch(`${BACKEND}${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL: string) {
  return axiosClient.delete(`${BACKEND}${URL}`).then((response) => response);
}
