// Based on https://themesbrand.com/velzon/html/material/ui-buttons.html

import React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark"
    | "link"
    | "light";
  isRounded?: boolean;
  size?: "lg" | "sm" | "default";
  type?: "button" | "submit";
  isBlock?: boolean;
  children?: React.ReactNode;
}

export const Button = ({
  color = "primary",
  isRounded = false,
  size = "default",
  type = "button",
  isBlock = false,
  children = null,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`btn btn-${color} waves-effect waves-light ${
        isRounded ? "rounded-pill" : ""
      } ${size !== "default" ? "btn-" + size : ""} ${isBlock ? "d-block" : ""}`}
      {...props}
    >
      {children}
    </button>
  );
};
