import { UserProps } from "@store/slices/userSlice";
import { getConfig } from "@utils/getConfig";
import { getRequest, postRequest } from "@utils/axiosInstance";

export function statusSystem(user: UserProps) {
  return getRequest("v2/isok", getConfig(user));
}

export function listAllUsers(user: UserProps) {
  return getRequest("v2/list", getConfig(user));
}

export function registerUser(
  user: UserProps,
  withLiveness = false,
  fotob64: string,
  idPessoa: string,
  data = {}
) {
  const url = withLiveness ? "v2/liveness/register" : "v2/register";

  return postRequest(url, { fotob64, idPessoa, data }, getConfig(user));
}

export function verifyUser(
  user: UserProps,
  withLiveness = false,
  fotob64: string,
  idPessoa: string,
  data = {}
) {
  const url = withLiveness ? "v2/liveness/verify" : "v2/verify";

  return postRequest(url, { fotob64, idPessoa, data }, getConfig(user));
}

export function deactivateUser(user: UserProps, idPessoa: string) {
  return postRequest("v2/deactivate", { idPessoa }, getConfig(user));
}

// Ninguem usando
/*
export function identifyUser(
  user: UserProps,
  withLiveness = false,
  fotob64: string
) {
  const url = withLiveness ? "v2/liveness/identify" : "v2/identify";

  return postRequest(url, getConfig(user), { fotob64 });
}
*/