/**
 * TODO's:
 * - O objeto user.listgt é um array de objetos que contém os subtokens, criar uma visualização para esta estrutura - DONE
 * - ao clicar em um dos subtokens, armazenar o subtoken na store - DONE
 * - ao realizar requests, enviar o subtoken no header filterAll - Doing
 * - ao selecionar o subtoken, causar um refresh na tela atual ou um reload na rota atual
 */

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getUser, setUserState } from "@store/slices/userSlice";

import Modal from 'react-bootstrap/Modal';

import logo from "@assets/images/logo-light.png";
import logoSm from "@assets/images/logo-sm.png";

export function LayoutDefault({ children }: { children: React.ReactNode }) {
  const [modalChangeToken, setModalChangeToken] = useState(false);
  const { user } = useSelector(getUser);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  /* TODO: refactor using store */
  const handleMenuChange = () => {
    const html = document.getElementsByTagName("html")[0];
    if (html.getAttribute("data-sidebar-size") === "lg") {
      html.setAttribute("data-sidebar-size", "sm");
    } else {
      html.setAttribute("data-sidebar-size", "lg");
    }
  };

  const handleMenuMobile = () => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];

    if (body.classList.contains("vertical-sidebar-enable")) {
      body.classList.remove("vertical-sidebar-enable");
    } else {
      body.classList.add("vertical-sidebar-enable");
      html.setAttribute("data-sidebar-size", "lg");
    }
  };

  const handleChangeBusiness = () => {
    navigate("/admin");
  };

  const handleTheme = () => {
    const themeElement = document.getElementsByTagName('html')[0];
    const actualTheme = themeElement.getAttribute('data-layout-mode');
    themeElement.setAttribute('data-layout-mode', actualTheme === 'light' ? 'dark' : 'light');
  }

  const setSubtoken = (subtoken: string) => {
    dispatch(setUserState({ ...user, filterAll: subtoken }));
  }

  return (
    <div>
      <Modal
        show={modalChangeToken}
        onHide={() => setModalChangeToken(false)}
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Escolha seu token:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.filterAll !== 'ALL' && (
            <div className="alert alert-dark d-flex justify-content-between align-items-center">
              <span>Limpar subtoken selecionado</span>
              <button className="btn btn-info btn-sm" onClick={() => setSubtoken('ALL')}>Selecionar</button>
            </div>
          )}

          {user?.listgt?.length > 0 && user.listgt.map((item: any, index: number) => {
              const parentElement = (
                <div key={item.key} className="d-flex justify-content-between align-items-center">
                  <span>Grupo: <strong>{item.grupoId}</strong></span> 
                  {user.filterAll === item.key && <span className="badge text-bg-warning">Selecionado</span>}
                  <button className="btn btn-info btn-sm" onClick={() => setSubtoken(item.key)}>Selecionar</button>
                </div>
              );
              
              const childrenElement = item.tokens.map((subToken: any) => {
                return (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="ps-3">{subToken.descricao}</span>
                    {user.filterAll === subToken.key && <span className="badge text-bg-warning">Selecionado</span>}
                    <button className="btn btn-info btn-sm" onClick={() => setSubtoken(subToken.key)}>Selecionar</button>
                  </li>
                );
              });
              
              return (
                <div key={item.key} className="card">
                  <div className="card-header">
                    {parentElement}
                  </div>
                  { childrenElement.length > 0 && (
                    <ul className="list-group list-group-flush">
                      {childrenElement}
                    </ul>
                  )}
                </div>
              )
            }
          )}
        </Modal.Body>
      </Modal>

      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none d-none d-md-inline"
                id="topnav-hamburger-icon"
                onClick={handleMenuChange}
                title="Expandir/Contrair menu"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none d-md-none"
                id="topnav-hamburger-icon"
                onClick={handleMenuMobile}
                title="Abrir menu"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-1 header-item d-none d-sm-flex">
                  <button 
                    type="button" 
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                    onClick={handleTheme}
                  >
                      <i className="bx bx-moon fs-22"></i>
                  </button>
              </div>
              {user.admin && (
                <div className="ms-1 header-item d-none d-sm-flex">
                  <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary shadow-none"
                    title="Trocar empresa"
                    onClick={handleChangeBusiness}
                  >
                    <i className="bx bxs-business fs-22"></i>
                  </button>
                </div>
              )}
              {user?.listgt?.length > 1 && (
                <div className="ms-1 header-item d-none d-sm-flex">
                    <button 
                      type="button" 
                      className="btn btn-primary shadow-none" 
                      onClick={() => setModalChangeToken(true)}
                      style={{height: "100%", borderRadius: "0"}}
                    >
                      Alterar Token

                      {user.filterAll && user.filterAll !== 'ALL' && (<span className="badge text-bg-warning ms-2">1</span>)}
                    </button>
                </div>
              )}
              <div className="header-item dropdown topbar-user">
                <Link to="/perfil" type="button" className="btn shadow-none">
                  <span className="d-flex align-items-center">
                    <span className="text-start ms-xl-2">
                      <span className="d-block ms-1 fs-12 text-muted user-name-sub-text">
                        Bem-vindo (a)
                      </span>
                      <span className="d-inline-block ms-1 fw-medium user-name-text">
                        {user.client}
                      </span>
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <a href="#" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSm} alt="ComputerID Logo" width={60} height={60} />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="ComputerID Logo" width={128} height={83} style={{marginTop: "15px"}} />
            </span>
          </a>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <div className="h-100">
          <ul className="navbar-nav">
            <li className="menu-title" style={{textAlign: "center"}}>
              <span data-key="t-menu">GESTÃO FACIAL ID</span>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/home" && " active"
                }`}
                to="/home"
                role="button"
              >
                <i className="mdi mdi-home"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-dashboards">
                  Dashboards
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/manageusers" && " active"
                }`}
                to="/manageusers"
                role="button"
              >
                <i className="mdi mdi-chart-line"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-usuarios">
                  Gestão de Usuários
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/documentacao" && " active"
                }`}
                to="/documentacao"
                role="button"
              >
                <i className="mdi mdi-file-document-multiple"></i>{" "}
                <span
                  data-key="t-dashboards"
                  className="intro-step-documentacao"
                >
                  Documentação
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/transacoes" && " active"
                }`}
                to="/transacoes"
                role="button"
              >
                <i className="mdi mdi-transfer"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-transacoes">
                  Transações
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/estatisticas" && " active"
                }`}
                to="/estatisticas"
                role="button"
              >
                <i className="mdi mdi-chart-line"></i>{" "}
                <span
                  data-key="t-dashboards"
                  className="intro-step-estatisticas"
                >
                  Estatísticas
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/perfil" && " active"
                }`}
                to="/perfil"
                role="button"
              >
                <i className="mdi mdi-face-man-profile"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-perfil">
                  Perfil
                </span>
              </Link>
            </li>
            <li>
              <hr style={{ borderColor: "white" }} />
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/logout" role="button">
                <i className="mdi mdi-exit-run"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-sair">
                  Sair
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay" onClick={handleMenuMobile}></div>
      <div className="main-content">
        <div className="page-content">{children}</div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                1999 - {new Date().getFullYear()} -{" "}
                <a
                  href="https://computerid.com.br/"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  Computer ID
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
