import { UserProps } from "@store/slices/userSlice";
import { getConfig } from "@utils/getConfig";
import { postRequest } from "@utils/axiosInstance";

export function getUsability(type = "env", user: UserProps) {
  return postRequest(`rel/ope/group/range/usability/${type}`, {}, getConfig(user));
}

export function searchWorstIds(user: UserProps) {
  return postRequest("rel/list/count/worst/ids", {}, getConfig(user));
}
