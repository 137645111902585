import React from "react";

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "info"
    | "warning"
    | "dark"
    | "light";
  children?: React.ReactNode;
}

export function Alert({ color = "primary", children }: AlertProps) {
  return (
    <div className={`alert alert-${color} shadow mb-xl-0" role="alert`}>
      {children}
    </div>
  );
}
