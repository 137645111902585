import { UserProps } from "@store/slices/userSlice";
import { getConfig } from "@utils/getConfig";
import { postRequest } from "@utils/axiosInstance";

export function getAllBusiness(user: UserProps) {
  return postRequest("clients/get", {}, getConfig(user));
}

export function getBusinessTransactions(
  user: { token: string, filterAll: string },
  period: "day" | "month",
  payload: object
) {
  return postRequest(`transactions/${period}`, payload, getConfig(user));
}

export function getBusinessProfile(user: UserProps) {
  return postRequest(`client/get`, {}, getConfig(user));
}

export function businessChangePassword(user: UserProps, newPassword: string) {
  return postRequest(`client/change_password`, { senha: newPassword }, getConfig(user));
}
