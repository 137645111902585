import { UserProps } from "@store/slices/userSlice";
import { getConfig } from "@utils/getConfig";
import { getRequest } from "@utils/axiosInstance";

export function getTokens(user: UserProps) {
  return getRequest(`tokens`, getConfig(user));
}

export function resendValidation(user: UserProps) {
  return getRequest(`resendvalidacao`, getConfig(user));
}
