import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line
} from "recharts";
import { LayoutDefault } from "@components/layout/Default";
import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { useEffect, useState } from "react";
import { getBusinessTransactions } from "@services/business";
import { MONTHS } from "@utils/constants";
import { Alert } from "@components/atomic/Alert/Alert";
import { useCookies } from "react-cookie";
import { Steps } from "intro.js-react";

import "intro.js/introjs.css";

export default function Home() {
  const { user } = useSelector(getUser);
  const [dayTransactions, setDayTransactions] = useState([]);
  const [monthTransactions, setMonthTransactions] = useState([]);
  const [isTrial, setIsTrial] = useState(false);
  const [cookies, , removeCookie] = useCookies();

  const steps = [
    {
      element: ".intro-step-dashboards",
      intro: "Controle suas transações por mês e ano através de gráficos.",
      position: "right",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".intro-step-usuarios",
      intro:
        "Principal módulo da plataforma, verifique o status do sistema, cadastre e valide usuários em tempo real.",
      position: "right",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".intro-step-documentacao",
      intro:
        "Tenha acesso a toda a documentação necessária para utilizar a aplicação.",
      position: "right",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".intro-step-transacoes",
      intro:
        "Filtre todas as suas transações por período, identificador e resultado de operação.",
      position: "right",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".intro-step-estatisticas",
      intro:
        "Acompanhe como está a saúde das verificações bem como usuários com maior número de tentativas sem sucesso.",
      position: "right",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".intro-step-perfil",
      intro: "Gerencie suas informações de usuário e acesso.",
      position: "right",
      highlightClass: "myHighlightClass",
    },
  ];

  const getBusinessData = async () => {
    try {
      const dayPayload = {
        ano: new Date().getFullYear(),
        mes: new Date().getMonth() + 1,
      };

      const { data: localDayTransactions } = await getBusinessTransactions(
        user,
        "day",
        dayPayload
      );

      const monthPayload = {
        ano: new Date().getFullYear(),
      };

      const { data: localMonthTransactions } = await getBusinessTransactions(
        user,
        "month",
        monthPayload
      );

      // padroniza dados - seria melhor ja vir correto da api
      const tmpDayTransactions = localDayTransactions.map((obj: any) => {
        return {
          dia: `Dia ${obj.dia}`,
          registros: obj.register,
          verificacoes: obj.verificacoes,
          identificacoes: obj.identificacoes,
        };
      });

      const tmpMonthTransactions = localMonthTransactions.map((obj: any) => {
        return {
          mes: `${MONTHS[obj.mes]}`,
          registros: obj.register,
          verificacoes: obj.verificacoes,
          identificacoes: obj.identificacoes,
        };
      });

      setDayTransactions(tmpDayTransactions);
      setMonthTransactions(tmpMonthTransactions);
    } catch (e) {
      console.log("Erro ao obter dados de dia ou mes", e);
    }
  };

  useEffect(() => {
    getBusinessData();

    if (cookies.pwd_facialid && cookies.user_facialid) {
      setIsTrial(true);
      removeCookie("pwd_facialid");
      removeCookie("user_facialid");
    }
  }, []);

  useEffect(() => {
    getBusinessData();
  }, [user]);

  return (
    <>
      <LayoutDefault>
        {isTrial && (
          <Steps
            enabled={true}
            steps={steps}
            initialStep={0}
            onExit={() => console.log("saiu")}
            options={{
              nextLabel: "Avançar",
              prevLabel: "Voltar",
              doneLabel: "Sair",
            }}
          />
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Dashboards</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4>Transações em {MONTHS[new Date().getMonth()]}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-lg-12"
                      style={{
                        height: dayTransactions.length === 0 ? "auto" : 300,
                      }}
                    >
                      {dayTransactions.length === 0 && (
                        <Alert color="info">Nenhum registro localizado.</Alert>
                      )}
                      {dayTransactions.length > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            width={500}
                            height={300}
                            data={dayTransactions}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dia" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              name="Registros"
                              dataKey="registros"
                              fill="#8884d8"
                            />
                            <Bar
                              name="Verificações"
                              dataKey="verificacoes"
                              fill="#82ca9d"
                            />
                            <Bar
                              name="Identificações"
                              dataKey="identificacoes"
                              fill="orange"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4>Transações em {new Date().getFullYear()}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-lg-12"
                      style={{
                        height: monthTransactions.length === 0 ? "auto" : 300,
                      }}
                    >
                      {monthTransactions.length === 0 && (
                        <Alert color="info">Nenhum registro localizado.</Alert>
                      )}
                      {monthTransactions.length > 0 && (
                        <>
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            width={500}
                            height={300}
                            data={monthTransactions}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="mes" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="registros" stroke="#8884d8" name="Registros" />
                            <Line type="monotone" dataKey="verificacoes" stroke="orange" name="Verificações" />
                            <Line type="monotone" dataKey="identificacoes" stroke="#82ca9d" name="Identificações" />
                          </LineChart>
                        </ResponsiveContainer>
                          
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDefault>
    </>
  );
}
