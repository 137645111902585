import { LayoutDefault } from "@components/layout/Default";
import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { Button } from "@components/atomic/Button/Button";
import { useEffect, useState } from "react";
import { Alert } from "@components/atomic/Alert/Alert";
import {
  deactivateUser,
  listAllUsers,
  registerUser,
  statusSystem,
  verifyUser,
} from "@services/users";
import { Grid, _ } from "gridjs-react";
import { ptBR } from "gridjs/l10n";
import { Modal } from "react-bootstrap";

export default function ManageUsers() {
  const { user } = useSelector(getUser);
  const [userList, setUserList] = useState([]);
  const [, setImageDetected] = useState("");
  const [idPessoa, setIdPessoa] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState("cadastrar");
  const [step, setStep] = useState("SHOW_ID_PESSOA");
  const [useLiveness, setUseLiveness] = useState(true);
  const [systemStatus, setSystemStatus] = useState("OK");

  const initialMessage: any = { color: "success", msg: "" };
  const [message, setMessage] = useState(initialMessage);

  const CID = window.CID;
  let fr: any = null;

  const getAllUsers = async () => {
    const {
      data: { data: localUsersList = [] },
    } = await listAllUsers(user);

    const newUsersList: any = localUsersList.list.map(
      (element: any) => new Array(element)
    );

    setUserList(newUsersList);
  };

  const getStatusSystem = async () => {
    const { data: localSystemStatus = "ERROR" } = await statusSystem(
      user
    );
    setSystemStatus(localSystemStatus);
  };

  useEffect(() => {
    getAllUsers();
    getStatusSystem();
  }, []);

  useEffect(() => {
    getAllUsers();
    getStatusSystem();
  }, [user]);

  const imageDetectedEvent = async (img: any) => {
    setMessage({
      color: "warning",
      msg: "Aguarde, processando imagem... Este processo pode demorar um pouco.",
    });
    setImageDetected(img);
    setOpenModal(false);

    if (action === "cadastrar") {
      try {
        const {
          data: { msg },
        } = await registerUser(user, useLiveness, img, idPessoa);

        setMessage({ color: "success", msg });
      } catch (error: any) {
        console.log("Erro retornado", error);

        const {
          response: { status },
        } = error;
        let msg = "";

        switch (status) {
          case 403: {
            msg = "Não autorizado";
            break;
          }
          case 600: {
            msg = "Erro inesperado, contate o suporte. Código de erro: 600";
            break;
          }
          case 601: {
            msg = "Pessoa já cadastrada!";
            break;
          }
          case 602: {
            msg = "Houve algum problema ao tentar criar o template";
            break;
          }
          case 606: {
            msg = "Qualidade da foto insuficiente.";
            break;
          }
          case 607: {
            msg = "Foto já cadastrada para outra pessoa.";
            break;
          }
          case 608: {
            msg = "No máximo 4 fotos são permitidas!";
            break;
          }
          case 609: {
            msg =
              "Erro ao tentar decodificar foto base64, contate o suporte. Código de erro 609.";
            break;
          }
          default: {
            msg = "Erro inesperado, tente novamente.";
            break;
          }
        }
        setMessage({ color: "danger", msg });
      }
      getAllUsers();
    }

    if (action === "verificar") {
      try {
        const {
          data: { msg, code },
        } = await verifyUser(user, useLiveness, img, idPessoa);

        /** REVISAR ISSO AQUI */
        if (code === 3) {
          setMessage({ color: "danger", msg });
        } else {
          setMessage({ color: "success", msg });
        }
      } catch (error: any) {
        console.log("Erro retornado", error);

        let {
          response: { status },
        } = error;
        let msg = "";

        switch (status) {
          case 403: {
            msg = "Não autorizado";
            break;
          }
          case 600: {
            msg = "Erro inesperado, contate o suporte. Código de erro: 600";
            break;
          }
          case 602: {
            msg = "Houve algum problema ao tentar criar o template";
            break;
          }
          case 604: {
            msg = "Pessoa não encontrada!";
            break;
          }
          case 605: {
            msg =
              "Foto falsa detectada (forte probablidade de não ser um rosto vivo).";
            break;
          }
          case 606: {
            msg = "Qualidade da foto insuficiente.";
            break;
          }
          case 609: {
            msg =
              "Erro ao tentar decodificar foto base64, contate o suporte. Código de erro 609.";
            break;
          }
          default: {
            msg = "Erro inesperado, tente novamente.";
            break;
          }
        }
        setMessage({ color: "danger", msg });
      }
    }

    setIdPessoa("");
  };

  const handleOpenModal = () => {
    if (step === "SHOW_FACE_RECON") {
      fr = new CID.FaceRecon({
        element: document.getElementById("showFaceRecon"),
        onDetection: imageDetectedEvent,
      });

      fr.run();
    }
  };

  const handleCloseModal = () => {
    if (step === "SHOW_FACE_RECON") {
      fr.destroy();
    }

    setStep("SHOW_ID_PESSOA");
    setIdPessoa("");
  };

  const handleUserActionButtonClick = (action: any, step: any) => {
    setAction(action);
    setStep(step);
    setOpenModal(true);
  };

  const handleDeactivateUserAction = async (idPessoa: string) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Deseja desativar o usuário ${idPessoa}?`)) {
        const {
          data: { msg: returnMessage = "" },
          status,
        } = await deactivateUser(user, idPessoa);
        if (status === 200) {
          setMessage({ color: "success", msg: returnMessage });
        } else {
          setMessage({ color: "danger", msg: returnMessage });
        }
        getAllUsers();
      }
    } catch (error) {
      alert("Erro ao desativar usuário.");
      console.log("Erro ao desativar usuário", error);
    }
  };

  const handleNextStepClick = () => {
    setStep("SHOW_FACE_RECON");
    setTimeout(() => {
      fr = new CID.FaceRecon({
        element: document.getElementById("showFaceRecon"),
        onDetection: imageDetectedEvent,
      });

      fr.run();
    }, 100);
  };

  return (
    <>
      <LayoutDefault>
        <>
          <Modal
            show={openModal}
            onShow={handleOpenModal}
            onHide={handleCloseModal}
          >
            <Modal.Header>
              <Modal.Title>
                {action === "cadastrar" && `Cadastrar usuário ${idPessoa}`}
                {action === "verificar" && `Verificar usuário ${idPessoa}`}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {step === "SHOW_FACE_RECON" && <div id="showFaceRecon"></div>}
              {step === "SHOW_ID_PESSOA" && (
                <div className="mb-3">
                  <label className="form-label">Informe o ID da pessoa:</label>
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    maxLength={60}
                    autoFocus
                    value={idPessoa}
                    onChange={(e) => setIdPessoa(e.target.value)}
                  />
                  <Button
                    onClick={handleNextStepClick}
                    disabled={idPessoa.trim() === ""}
                  >
                    Prosseguir
                  </Button>
                  <hr />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="light"
                onClick={() => {
                  setOpenModal(false);
                  handleCloseModal();
                }}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Gestão de Usuários</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {message.msg !== "" && (
                  <Alert color={message.color}>{message.msg}</Alert>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className="card">
                  <div className="card-header">Status do sistema</div>
                  <div className="card-body">
                    <h2>
                      {systemStatus === "OK" && (
                        <span className="badge text-bg-success">
                          ONLINE <i className="mdi mdi-check-decagram"></i>
                        </span>
                      )}
                      {systemStatus !== "OK" && (
                        <span className="badge text-bg-danger">
                          OFFLINE <i className="mdi mdi-heart-broken"></i>
                        </span>
                      )}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="card">
                  <div className="card-header">Operações:</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <Button
                          color="success"
                          isRounded={true}
                          isBlock={true}
                          onClick={() =>
                            handleUserActionButtonClick(
                              "cadastrar",
                              "SHOW_ID_PESSOA"
                            )
                          }
                        >
                          Cadastrar <i className="mdi mdi-account-box" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="card">
                  <div className="card-header">Tipo de verificação</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="form-check"
                          style={{
                            paddingTop: "0.5rem",
                            paddingBottom: "0.5rem",
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="useLivenessCheckbox"
                            checked={useLiveness}
                            onChange={() => setUseLiveness(!useLiveness)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="useLivenessCheckbox"
                          >
                            Usar liveness?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {userList.length === 0 && (
                      <Alert>Nenhum usuário cadastrado</Alert>
                    )}
                    {userList.length > 0 && (
                      <Grid
                        data={userList}
                        columns={[
                          {
                            name: "ID Pessoa",
                            formatter: (cell: any) => {
                              return _(
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ flexGrow: 1 }}>{cell}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <Button
                                      color="warning"
                                      size="sm"
                                      style={{ marginRight: "15px" }}
                                      onClick={() => {
                                        setIdPessoa(cell);
                                        handleUserActionButtonClick(
                                          "verificar",
                                          "SHOW_FACE_RECON"
                                        );
                                      }}
                                    >
                                      Verificar
                                    </Button>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() =>
                                        handleDeactivateUserAction(cell)
                                      }
                                    >
                                      Desativar
                                    </Button>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 15 }}
                        language={ptBR}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </LayoutDefault>
    </>
  );
}
